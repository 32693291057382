.project-entry {
    width: 40em;
    padding-bottom: 1em;
}

.project-name-link {
    font-size: large;
}

.project-date-string {
    display: inline;
    font-size: small;
}

.project-description {
    font-size: medium;
}

.email-link {
    padding-left: 3px;
    font-family: monospace;
    font-variant: small-caps;
    font-size: large;
}